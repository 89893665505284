// notificationHelper.js
import { Notification, toast } from 'components/ui'

export function pushNotification(
    title,
    message,
    type = 'success',
    duration = 4500,
    placement = 'top-end'
) {
    toast.push(
        <Notification title={title} type={type} duration={duration}>
            {message}
        </Notification>,
        {
            placement: placement,
        }
    )
}
