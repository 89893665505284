const appConfig = {
    apiBaseUrl: process.env.REACT_APP_REST_API_ENDPOINT_KEY,
    apiPrefix: '/api',
    authenticatedEntryPath: '/calendrier',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
